import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';
import { Pedido } from '../models/pedido';
import { ProductoTipo } from '../models/productoTipo';
@Injectable()
export class PedidoService {
    // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }
   
   
  consultarPedidos(idRazonSocial): Observable<Pedido[]>{
   
    let param = {
    idRazonSocial: idRazonSocial
  }
     const json = JSON.stringify(param);
  
      return this._http
        .post(`${this._utils.url}Pedido/consultarPedidos/` ,json, {headers: this.headers} )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

   consultarProductoTipo(): Observable<ProductoTipo[]>{
   
   
   
  
      return this._http
        .get(`${this._utils.url}Pedido/consultarProductoTipo/` )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
  }

  agregarTipoProducto(params: any){
  
  const json = JSON.stringify(params);
   return this._http
          .post(`${this._utils.url}Pedido/agregarTipoProducto/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }


   selectProductoInventario(lista){
  
  const json = JSON.stringify(lista);
   return this._http
          .post(`${this._utils.url}Pedido/selectProductoInventario/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return JSON.parse(response.text());
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }

  consultarPedidosFiltros(lista){
  
  const json = JSON.stringify(lista);
   return this._http
          .post(`${this._utils.url}Pedido/consultarPedidosFiltros/`, json, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                console.log(JSON.parse(response.text()));
                return JSON.parse(response.text());
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
  }



      agregarPedido(PedidoModelo) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(PedidoModelo);
        const params = json;
        return this._http
          .post(`${this._utils.url}Pedido/agregarPedido/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.statusText;
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }

    agregarPedidos(PedidoModelo) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(PedidoModelo);
        const params = json;
        return this._http
          .post(`${this._utils.url}Pedido/agregarPedidos/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return JSON.parse(response.text());
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }

      consultarFolio(idRazonSocial) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        let param = {
          idRazonSocial: idRazonSocial
        }
        const json = JSON.stringify(param);
        const params = json;
        return this._http
          .post(`${this._utils.url}Pedido/consultarFolio/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return JSON.parse(response.text());
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }
   
  consultarPedidosConvenio(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/consultarPedidosConvenio/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  consultarPedidosSolicitudes(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/consultarPedidosSolicitudes/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  consultarPedidoSolicitud(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/consultarPedidoSolicitud/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  consultarMotivosCancelacion() {
    return this._http
    .get(`${this._utils.url}Pedido/consultarMotivosCancelacion/` )
    .map((response) => {
      try {
        if (response.json() !== 'null') {
          return JSON.parse(response.text());
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    });
  }

  cancelarPedido(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/cancelarPedido/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  editarPedido(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/editarPedido/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  agregarPedidosImportador(params) {
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/agregarPedidoImportador/`, json, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  validarArchivoOXXO(nombreArchivo) {
    let param = {      
      nombreArchivo: nombreArchivo
    }
    
    return this._http
      .post(`${this._utils.url}Pedido/validarArchivoOXXO/`, param, {headers: this.headers} )
      .map((response: any) => {
        try {
          if(response._body == "True"){            
            return true;
          }else{
            return false;
          }
        } catch (error) {
          console.log(error);
          return false; 
        } 
      });
  }

  agregarPedidosImportadorOXXO(params, idRazonSocial, idUsuario, nombreArchivo) {
    let param = {
      pedidoString: JSON.stringify(params),
      idRazonSocial: idRazonSocial,
      idRealizoAlta: idUsuario,
      nombreArchivo: nombreArchivo
    }
    //const json = JSON.stringify(params);    
    return this._http
      .post(`${this._utils.url}Pedido/agregarPedidoImportadorOXXO/`, param, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  consultarPedidoConvenioTransportePreferente(id) {
    return this._http
      .get(`${this._utils.url}Pedido/consultarPedidoConvenioTransportePreferente?idPedido=`+id, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  autoCompletClientes(idRazonSocial){
    return this._http.get(`${this._utils.url}Pedido/autocompleteClientes?idRazonSocial=` +idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }


  autoCompleteRemitenteDestinatario(idRazonSocial){
    return this._http.get(`${this._utils.url}Pedido/autocompleteRemitenteDestinatario?idRazonSocial=` +idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  consultarTiposOperacion(idRazonSocial): Observable<any[]> {
    return this._http.get(`${this._utils.url}TipoOperacion/consultarTiposOperacion?idRazonSocial=` +idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }


  consultarAreas(idRazonSocial){
    return this._http.get(`${this._utils.url}PortalEmbarcadorSolicitud/consultarArea?idRazonSocial=` +idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  consultarPlazas(idRazonSocial){
    return this._http.get(`${this._utils.url}PortalEmbarcadorCliente/consultarClientesCatalogo?idCedisTipo=` + 5 + '&idRazonSocial=' + idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  autoCompletConvenios(idRazonSocial,idCedis){
    return this._http.get(`${this._utils.url}Pedido/autocompleteConvenios?idRazonSocial=` +idRazonSocial  + '&idCedis=' + idCedis, {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  obtenerProductosConvenio(idRazonSocial,idCedis){
    return this._http.get(`${this._utils.url}Pedido/obtenerProductosConvenio?idRazonSocial=` +idRazonSocial  + '&idConvenio=' + idCedis, {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  consultarRutaClasificacion(){
    return this._http.get(`${this._utils.url}PortalEmbarcadorSolicitud/consultarRutaClasificacion`, {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  consultarAlcancePedido(){
    return this._http.get(`${this._utils.url}PortalEmbarcadorSolicitud/consultarRutaTipo`, {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  consultarProductos(idRazonSocial){
    return this._http.get(`${this._utils.url}PortalEmbarcadorProducto/consultarProductosGeneral?idRazonSocial=` +idRazonSocial , {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

  agregarPedidoManual(params){
    const embarque = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/CrearPedidoManual/`, embarque, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  enviarPedidoZam(params){
    const embarque = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Pedido/enviarPedidoZAM/`, embarque, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            return JSON.parse(response.text());
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }

  agregarIdExternoPedido(idRazonSocial,idPedido,idExterno){
    return this._http.get(`${this._utils.url}Pedido/agregarIdExternoPedido?idRazonSocial=` +idRazonSocial  + '&idPedido=' + idPedido + '&idPedidoExterno=' + idExterno, {headers: this.headers})
    .map((response) => {
      try {
        if(response.statusText !== null){
          return JSON.parse(response.text());
        }
      } catch (error) {
        console.log(error)
        return null 
      }
    })
  }

}
