import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../utils/data.service';
import { Observable } from 'rxjs/Observable';
import { modalComponent } from '../../../../utils/modal/modal.component';
import { MatDialog } from '@angular/material';
import { MAT_DIALOG_DATA,MatDialogRef,MatSnackBar } from '@angular/material';
import { Sitio } from '../../../../models/cedis';
import { CedisService } from '../../../../services/cedis.service';
import { ClienteTipoModelo } from 'src/app/models/configuracion/catalogos/clientes/tipos/clientes_tipo.modelo';
import { formatDate } from '@angular/common';
import { PaisService } from '../../../../services/configuracion/catalogos/generales/pais.service';
import { EstadoService } from '../../../../services/configuracion/catalogos/generales/estado.service';
import { CiudadService } from '../../../../services/configuracion/catalogos/generales/ciudad.service';
import {utils} from '../../../../utils/utils';
declare let L;

@Component({
  selector: 'app-sitio-add',
  templateUrl: './sitioAdd.component.html',
   styleUrls: ['../../estilosModales.scss']
})

export class SitiosAddComponent implements OnInit {
  public _utils:utils;
  public selectedValue: number;
  public selectedCliente: number;
  public sitioModelo: Sitio;
  public selectedTipo :  number;
  public idRazonSocial: any;
  public usuarioSession: any;
  public idUsuario : any;
  sitioAgregar: FormGroup;
  titulo = 'Agregar';
  public idCedis: number = null;
  public chckActivado = false;
    
  public isZAM: any;

  public clientetiposModelo: ClienteTipoModelo;
  public selectedTipoCliente: number;
  public selectedEstatus: number;
  public selectedEstado: number;
  public selectedCiudad: number;
  public selectedPais: number;
  public listaclientetipo: ClienteTipoModelo[] = [];
  clienteAgregar: FormGroup;
  listaClientes: any = [];
 
  public IdEmpresaCliente: number = null;
  public listaPais : any;
  public listaEstado: any;
  public listaCiudad: any;
  public idSitio : any;
  public isZAMVal: boolean=false;

  listaTipoSitios: any[] = [];

  estatus = [
    { value: 'Alta', viewValue: 'Alta' },
    { value: 'Baja', viewValue: 'Baja' }
  ];
  constructor(private formBuilder: FormBuilder, private sitioService: CedisService, public dialog: MatDialogRef<SitiosAddComponent>, public dataService: DataService,
    public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.sitioModelo = new Sitio(0,"","","","",0,"",0,"","","","","","","","","","","","","","",0,null,null,0,0,0,0,"");
      this.dataService.guardado = false;
      this._utils = new utils();

      L.Icon.Default.imagePath = 'assets/leaflet/images';

      if(this.isZAMVal){
        this.listaTipoSitios = [
          //{ idTipo: 1, descripcion: 'Almacen' },
          { idTipo: 2, descripcion: 'Patio' },
          { idTipo: 3, descripcion: 'Cliente' },
          { idTipo: 5, descripcion: 'Plaza'}
        ];
      }else {
        this.listaTipoSitios = [
          { idTipo: 3, descripcion: 'Cliente' },
          { idTipo: 5, descripcion: 'Plaza'}
        ];
      }
  }
  ngAfterViewInit(){
    // Set up the OSM layer
    const mapSitio = L.map('mapSitio').setView([25.67098441, -100.34368694], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mapSitio);

    //var searchControl = L.esri.Geocoding.geosearch().addTo(mapSitio);
    var results = L.layerGroup().addTo(mapSitio);

    var ligit = L.marker([25.67098441, -100.34368694])
    .addTo(mapSitio);  

    mapSitio.on('click', (e) => { 
      var lat = e.latlng.lat;
      var lon = e.latlng.lng;

      //Clear existing marker, 

      if (ligit != undefined) {
            mapSitio.removeLayer(ligit);
      };
    //Add a marker to show where you clicked.
      ligit = L.marker([lat,lon]).addTo(mapSitio); 
      this.sitioModelo.longitud = lon;
      this.sitioModelo.latitud = lat;
    })
  }
  ngOnInit() {
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.idUsuario = this.usuarioSession.idUsuario;
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
    this.isZAM = this.usuarioSession.activoZAM;
    if(this.isZAM != 0){
      this.isZAMVal = true
    }else{
      this.isZAMVal = false
    }

    if(this.idRazonSocial == 1128){
      this.isZAMVal = false;
    }

    if(this.idSitio != 0){
      this.titulo = 'Editar'
      this.consultarSitio()
    } else{
      this.sitioModelo.nombre  = "Sin Asignar"
    }

    this.buildForm();
    this.dataService.mensajeAlert = '';
  }

  cerrarModal() {
    this.dataService.dialogRef.close();
  }

  buildForm() {
    this.sitioAgregar = this.formBuilder.group({
      nombre: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      longitud: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      latitud: this.formBuilder.control(null, [ Validators.maxLength(50)]),
      nombreNegocio: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]), 
      tipo: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]), 
      contacto1: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]), 
      contacto2: this.formBuilder.control(null, [ Validators.maxLength(50)]), 
      cliente: this.formBuilder.control(null, []),
      codigo: this.formBuilder.control(null, [Validators.required, Validators.maxLength(50)]),
      estatus: this.formBuilder.control(null, [Validators.required])
    });
    
    this.consultarEmpresaClientes();
  }

  consultarEmpresaClientes(){
    this.sitioService.consultarClientes(this.idRazonSocial).subscribe(
      (data) => {
        if(data !=null){
          this.listaClientes = data;
        }
      },
      (error)=>{
        console.log(error);
      }
    );
  }

  consultarSitio(){
    this.sitioService.consultarSitio(this.idSitio).subscribe(
      (data) => {
        if(data !=null){
          this.sitioModelo = data[0];

          this.bindForm();
          console.log(this.sitioModelo);
          console.log(this.sitioAgregar);
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  agregar() {
    this.sitioModelo.idCedis= null;
    
    this.sitioService.agregarSitio(this.sitioModelo).subscribe(
      (result) => {
        if (result !== 'null') {
          this.dataService.guardado = true;
        } else {
          this.dataService.mensajeAlert = result;
        }
        this.cerrarModal();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editar() {
    this.sitioModelo.idCedis = this.idSitio
    
    this.sitioService.editarSitio(this.sitioModelo).subscribe(
      (result) => {
        if (result !== 'null') {
          this.dataService.guardado = true;
        } else {
          this.dataService.mensajeAlert = result;
        }
        this.cerrarModal();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.bindModel();
    if(this.sitioModelo.tipo == 1){
      if(this.sitioModelo.cliente == 0 || this.sitioModelo.cliente == null){
        this._utils.toast('Favor de seleccionar un Cliente', this.snackBar);
      } else {
          if(this.idSitio != 0) {
            this.editar();
          }else {
            this.agregar();
          }
        }   
    }else {
      if(this.idSitio != 0){
        this.editar();
      }else {
        this.agregar();
      }
    }
  }

  bindModel() {
    this.sitioModelo.idRazonSocial = this.idRazonSocial
    this.sitioModelo.nombre = this.sitioAgregar.get('nombre').value;
    this.sitioModelo.longitud = this.sitioAgregar.get('longitud').value;
    this.sitioModelo.codigoCedis = this.sitioAgregar.get('codigo').value;
    this.sitioModelo.nombreNegocio = this.sitioAgregar.get('nombreNegocio').value;
    this.sitioModelo.contacto1 = this.sitioAgregar.get('contacto1').value;
    this.sitioModelo.tipo = this.sitioAgregar.get('tipo').value;
    this.sitioModelo.latitud = this.sitioAgregar.get('latitud').value;
    this.sitioModelo.contacto2 = this.sitioAgregar.get('contacto2').value;
    this.sitioModelo.estatus = this.sitioAgregar.get('estatus').value;

    this.sitioModelo.cliente = this.sitioAgregar.get('cliente').value;
  }

  bindForm() {
    this.sitioAgregar.get('nombre').setValue(this.sitioModelo.nombre);
    this.sitioAgregar.get('longitud').setValue(this.sitioModelo.longitud);
    this.sitioAgregar.get('codigo').setValue(this.sitioModelo.codigoCedis);
    this.sitioAgregar.get('nombreNegocio').setValue(this.sitioModelo.nombreNegocio);
    this.sitioAgregar.get('contacto1').setValue(this.sitioModelo.contacto1);
    this.sitioAgregar.get('tipo').setValue(this.sitioModelo.tipo);
    this.sitioAgregar.get('latitud').setValue(this.sitioModelo.latitud);
    this.sitioAgregar.get('contacto2').setValue(this.sitioModelo.contacto2);
    this.sitioAgregar.get('estatus').setValue(this.sitioModelo.estatus);

    this.sitioAgregar.get('cliente').setValue(this.sitioModelo.cliente);
  }

}