import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { archivoRoutech } from 'src/app/models/archivoRoutech'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-subir-archivo-routech',
  templateUrl: './subir-archivo-routech.component.html',
  styleUrls: ['./subir-archivo-routech.component.scss']
})
export class SubirArchivoRoutechComponent implements OnInit {
  public displayedColumns = ['idRuta', 'NoViajeZam', 'Nombre','peso', 'descargar'];
  dataSource: Array<any> = [];
  idRazonSocial;
  usuarioSession;
  tablaShow = true;
  formularioShow = false;
  settings: Settings;
  tipoAgregar: FormGroup;
  public archivoRoutech = new archivoRoutech('',0,0,'',0,0);

  archivos = [];
  archivoBase64: string | ArrayBuffer | null = null;
  archivo;
  constructor(
    public dialogRef: MatDialogRef<SubirArchivoRoutechComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rs: RutaService,
    public snackBar: MatSnackBar,
    public appSettings: AppSettings,
    private formBuilder: FormBuilder
  ) { 
    this.settings = this.appSettings.settings;

  }

  ngOnInit() {
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario"));
    this.idRazonSocial = this.usuarioSession.idRazonSocial;
    this.archivoRoutech.idRuta = this.data.idRuta;
    this.archivoRoutech.idRazonSocial = this.idRazonSocial;
    if(this.data.noViaje.indexOf(',') != -1){
      this.archivoRoutech.noviaje = this.data.noViaje.substring(0,this.data.noViaje.indexOf(','));
    }else{
      this.archivoRoutech.noviaje = this.data.noViaje;
    }
    this.consultarArchivosRuta();
  }

  consultarArchivosRuta(){
    this._rs.obtenerArchivosRuta(this.data.idRuta, this.idRazonSocial).subscribe(result => {
      if(result.length > 0){
        this.dataSource = result
        this.archivos = result;
      }else{
        this.dataSource = [];
      }
    },error => {
      console.log(error);
    })
  }

  cerrarModal(){
    this.dialogRef.close();
  }

  formulario(){
    this.settings.loadingSpinner = true;
    this.tablaShow = false;
    this.formularioShow = true;
    this.settings.loadingSpinner = false;
  }


  handleFileInputPDF(event){
    this.settings.loadingSpinner = true;

    if(this.archivoRoutech.descripcion == ""){
      this.archivoRoutech.descripcion = ((event.target.files[0].name).replace(/\s+/g, "")).substring(0,event.target.files[0].name.length - 6); //instrucion para quitar espacios y tambien el tipo de archivo
    }
    this.archivoRoutech.peso = parseInt(event.target.files[0].size)  / 1024

    const archivoSeleccionado = event.target.files[0];
    const lector = new FileReader()


    lector.onload = function() {
        // this.archivoBase64 contendrá el Data URL del archivo
        this.archivoBase64 = lector.result;
        this.archivoRoutech.archivo = this.archivoBase64;

    }.bind(this);

    lector.readAsDataURL(archivoSeleccionado);

    this.settings.loadingSpinner = false;
  }


  guardarArchivo(){
    this._rs.agreagarArchivoRuta(this.archivoRoutech).subscribe(result => {
      if(result == 1){
        this.reload();
      }else{  
        console.log("Error al subir el archivo");
      }
    },error=>{
      console.log(error);
    });
  }

  reload(){
    this.settings.loadingSpinner = true;
    this.consultarArchivosRuta();
    this.tablaShow = true;
    this.formularioShow = false;
    this.settings.loadingSpinner = false;
  }


  descargaArchivoPDF(idArchivo){
    this.settings.loadingSpinner = true;
    this.archivo = this.archivos.find(function(objeto) {
      return objeto.idArchivoRoutech === idArchivo
    });

    this.generarArchivo();
    this.settings.loadingSpinner = false;
  }

  generarArchivo(){
    var base64 = this.archivo.archivo.substring(28,this.archivo.archivo.length);
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);


    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convertir el arreglo de bytes en un objeto Blob
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: 'application/pdf' });

    // Crear un enlace para descargar el archivo
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = this.archivo.nombreArchivo + '.pdf';
    // Simular un clic en el enlace para iniciar la descarga
    link.click();
    
  }

}
