import * as $ from 'jquery';
import { MatSnackBarConfig } from '@angular/material';

export class utils{
  
	public url:string;
  public url2:string;

  config = new MatSnackBarConfig();

	constructor(){
    //this.url = "http://localhost/ROUTECH2/API/";
    //this.url = "http://localhost/ROUTECHPROD/API/";
    //this.url = "http://localhost:58895/api/";
    // this.url = "http://13.85.28.87/PLANNER_BACK/api/";
    this.url = "https://lisplanner.azurewebsites.net/api/"
    //this.url = "http://localhost:58895/api/";
    //this.url = "http://13.85.28.87/PLANNER_BACK_DEV_MRS/api/";
	  //this.url = "http://13.85.28.87/PLANNER_BACK_DEV/api/";
    // this.url = "http://13.85.28.87/PLANNER_BACK/api/";
    // this.url = "http://13.85.28.87/PLANNER_BACK_PIRAMIDE/api/";
    //this.url2 = "https://appinpagos.com.mx/appin_demo/api/"; 
    //this.url2 = "http://localhost/ROUTECH/api/";
    //this.url = "http://13.85.28.87/appin/";
    //this.url = "http://192.168.15.28:58895/"; 
    //this.url = "";   
    // this.url = "https://planner.servicios-lis.com.mx/api/"

    this.config.panelClass = ['custom-snackbar'];
    this.config.duration = 6500;
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'center';

  }
  
   
	
	toast(mensaje, snackBar){
    const config = new MatSnackBarConfig();
    config.panelClass = ['custom-snackbar'];
    config.duration = 8000;

    snackBar.open(mensaje, "cerrar", config);
  }

  toast12(mensaje, snackBar){
    const config = new MatSnackBarConfig();
    config.panelClass = ['custom-snackbar'];
    config.duration = 12000;

    snackBar.open(mensaje, "cerrar", config);
  }

	modalGrande(){
		$('.mat-dialog-container').css('border-radius', '0px');
   /*  $('.cdk-overlay-pane').css('flex', '1 1 50%');
    $('.cdk-overlay-pane').css('max-width', '65%');
    $('.cdk-overlay-pane').attr('fxflex', '50');
    $('.cdk-overlay-pane').attr('fxflex.gt-sm', '80');
		$('.cdk-overlay-pane').attr('fxflex.sm', '100');
		$('.cdk-overlay-pane').attr('fxLayout', 'column'); */
  }

}